@import url('https://fonts.googleapis.com/css2?family=Teko:wght@300;400;500;600&display=swap');

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
body {
  background-color: #212121!important;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.bg-dark {
    background-color: #212121!important;
}
.navbar-dark.bg-dark {
  background-color: #212121!important;
}
.home-banner-bg {
  background: url("images/1873.jpg");
 background-size: cover;
    min-height: 500px;
    background-color: #212121!important;
    background-position: bottom;
    
    }

    .discord {
  /*background: url("images/back.jpg"); */
  background: url("images/new.jpg");
 background-size: cover;
    min-height: 500px;
    background-color: #212121!important;
    background-position: bottom;
    margin-top: -50px;
    
    }
    .future-plans {
      background: url("images/back.jpg");
    }

    .gallery-bg {
  background: url("images/new.jpg");
  background-attachment: fixed;
 background-size: cover;
    min-height: 500px;
    background-color: #212121!important;
    background-position: bottom;
    
    }
.navbar-dark.bg-dark:after {

position: absolute;
    content: "";
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    background: rgb(255,65,213);
    background: -moz-linear-gradient(left, rgba(255,65,213,1) 0%, rgba(0,177,255,1) 100%);
    background: -webkit-linear-gradient(left, rgba(255,65,213,1) 0%,rgba(0,177,255,1) 100%);
    background: linear-gradient(to right, rgba(255,65,213,1) 0%,rgba(0,177,255,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff41d5', endColorstr='#00b1ff',GradientType=1 )

}

.navbar-expand .navbar-nav .nav-link {
    padding-right: 1.2rem !important;
    padding-left: 2.2rem !important;
}
.navbar-dark .navbar-nav .nav-link {
  color: #fff;
}
.navbar-dark .navbar-nav .active>.nav-link, .navbar-dark .navbar-nav .nav-link.active, .navbar-dark .navbar-nav .nav-link.show, .navbar-dark .navbar-nav .show>.nav-link {
  font-weight: bold;
}

.logo:after {
  content: "";
    width: 1px;
    height: 100%;
    right: -20px;
    top: 0;
    position: absolute;
    background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,0.88) 50%, rgba(255,255,255,0) 100%);
    background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,0.88) 50%,rgba(255,255,255,0) 100%);
    background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,0.88) 50%,rgba(255,255,255,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#00ffffff',GradientType=0 );
}
.navbar-dark .navbar-brand {
    color: #fff;
    border-right: solid 1px #fafafa;
    padding-right: 20px;
}


.home-top-logo {
  margin-top: 50px;
  width: 300px;
}
.mint-button {
  background: #ff0015;
    background: -moz-linear-gradient(left, #ff0015 0%, #b50614 100%);
    background: -webkit-linear-gradient(left, #ff0015 0%, #b50614 100%);
    background: linear-gradient(to right, #ff0015 0%, #b50614 100%);
    background-color: transparent;
    border: 0px solid transparent;
    padding: .375rem 1.75rem !important;
    font-size: 1rem;
    border-radius: .25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    border-color: #2a2a2a !important;
    border:  3px solid #ff4756 !important;
    box-shadow: 0px 5px 5px rgb(0 0 0 / 80%) !imporant;
}
.mint-button:hover {
    box-shadow: 2px 8px 5px rgb(0 0 0 / 80%) !imporant; 
}
.gallery img {
  width: 100%;
}
.gallery .images > div img {
      display: block;
    background: #222227;
    padding: 15px;
    border-radius: 20px;
    border: 1px solid rgb(255 255 255 / 16%);

}
.accordion-button {
    color: #fff !important;
    background-color: #2a2a2c !important;
    box-shadow: 0px;
    border: 0;
        font-weight: 700;
    font-size: 20px !important;
}
.accordion-item {
 border: 0px !important; 
}
.accordion-body {
    padding: 1rem 1.25rem !important;
    background: #2a2a2c !important;
    color: #fff !important;
    text-align: left !important;
}
.accordion-item {
      margin-bottom: 20px !important;
}
.accordion-button:focus {
  box-shadow: none !important;
}
.accordion-body {
  border-top: 1px solid rgb(255 255 255 / 15%);
}
.meet-team .images {
  width: 100% !important;

}
.meet-team .images .single-team {
    background: #222227;
    padding: 30px;
    border-radius: 20px;
    border: 1px solid rgb(255 255 255 / 16%);
    box-shadow: 8px 8px 10px rgb(255 0 21 / 70%);
    transition: all 0.3s ease-in-out;
}
.meet-team .images .single-team:hover {
 box-shadow: 15px 15px 10px rgb(255 0 21 / 40%); 
}
.meet-team .images img {
  width: 100%;
  border-radius: 20px;
}
.meet-team .images .team-social img {
  width: 42px;
  border-radius: 0px;
}

.meet-team .images .team-detail {
  color: #fff;
  text-align: left;
  text-transform: uppercase;
      font-size: 16px;
      font-weight: bold;
      padding: 30px;
      padding-left: 0px;
      padding-right: 0px;
      padding-bottom: 10px;
      align-items: center;
}
.meet-team .images .team-detail a {
  color: #fff
}
.meet-team .images .team-social {
  text-align: right;
}
.meet-team .my-5 {
    margin-top: 0rem!important;
    margin-bottom: 2.5rem!important;
}
h2,h3 {
      font-size: 48px !important;
    margin: 0 0 18px;
    text-transform: uppercase !important;
    font-family: 'Teko', sans-serif;
}
ul.social-nav li {
    display: block;
    padding-left: 40px;
}
ul.social-nav {
    margin: 0;
    padding: 0;
    display: flex;
}
.fooeter-row {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 32px;
}
.footer-bottom {
    text-align: center;
    padding-top: 35px;
    margin-top: 35px;
    padding-bottom: 35px;
    border-top: 1px solid rgb(62 62 67 / 34%);
}
.footer-bottom p {
    margin: 0;
    font-size: 13px;
    color: #fff;
}
.navbar-dark .navbar-nav a {
    color: #fff;
    text-decoration: none;
    padding-right: 1.2rem !important;
    padding-left: 2.2rem !important;
    display: block;
    padding: .5rem 1rem;
    font-family: 'Teko', sans-serif;
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 1px;
}


/* animation */
.example-enter {
  opacity: 0.01;
}

.example-enter.example-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.example-leave {
  opacity: 1;
}

.example-leave.example-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}
.alien-gif {
  width: 100%;
   background: #222227;
    padding: 30px;
    border-radius: 20px;
    border: 1px solid rgb(255 255 255 / 16%);
}
.about h2 {
  text-align: left !important;
}
.about p {
  text-align: left !important;
}
.about button {
float: left;  
}

.team-text {
  font-size: 17px;
  text-transform: none;
  font-weight: normal;
}
.future-box  {
  border: solid 5px #ff0015;
  padding: 30px;
  text-align: left;
  margin-top: 80px;
  margin-bottom: 30px;

}
.future-box ul {
  margin-top: 20px;
  margin-bottom: 20px;
}
.future-box ul li {
  list-style-type: none;
  font-size: 17px;
  margin-bottom: 20px;
  text-align: center;
}

.future-box ul li:before {
   display: inline-block;
    content: "-";
    width: 1em;
    margin-left: -1em;
}
.future-box .shutle-image {
  width: 120px;
  margin:  0 auto;
  display: block;
  margin-top: -70px;
  background: linear-gradient(#1f0417, #2b081c);
  padding: 20px;
  padding-top: 0px;
}
.mobmenu {
  display: none !important;
}

@media screen and (max-width: 600px) 
{
  .home-banner-bg {
      min-height: auto;  
  }
  .future-box {
        padding: 20px;
  }
  .mobmenu {
  display: flex !important;
}
.deskmenu {
  display: none !important;
}
.logo {
  width: 120px;
  margin-left: 20px;
}
.navbar-dark .navbar-toggler {
      margin-right: 10px;
}
  
}
